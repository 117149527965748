import React from "react";
import "./HeroSection.scss";

const HeroSection = () => {
  return (
    <div className="news-hero-container">
      <div className="hero-img"></div>
      <div className="hero-text-con d-flex flex-row">
        <div className="waw-headline">
          <p className="p1">News</p>
          <p className="p2">and</p>
          <p className="p3">Updates</p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
