import * as React from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";
import Articles from "../components/News/Articles";
import HeroSection from "../components/News/HeroSection";
import Seo from "../components/seo";

const News = () => (
  <Layout>
    <Seo title="News and Updates" />
    <HeroSection />
    <Articles />
  </Layout>
);

export default News;
